import React from "react";

function LockDropDown(props) {
    function selectHandler(event) {
        props.onLockSelected(event.target.value);
    }

    return (<div>
        <label>Blokada: </label>
        <select disabled={props.locksHidden} value={props.value} onChange={selectHandler}>
            <option value="BLANK"> --- Wybierz rodzaj blokady --- </option>
            {props.locks.map((lock) => (
                <option value={lock.key}>{lock.key} - {lock.balance}</option>))}
        </select>
    </div>);
}

export default LockDropDown;