import React, {useLayoutEffect} from "react";
import './LastOperations.css';
import {useState} from "react";

function LastOperations(props) {

    const [filterDate, setFilterDate] = useState('.*');
    const [filterAmount, setFilterAmount] = useState('.*');
    const [filterDescription, setFilterDescription] = useState('.*');
    const [filterAccount, setFilterAccount] = useState('.*');
    const [lastOperations, setLastOperations] = useState([]);

    function fetchLastOperation() {
        fetch(props.server + '/operation/getOperations')
            .then(response => {
                return response.json();
            })
            .then((data) => {
                setLastOperations(data);
            });
    }

    function onRefreshClicked() {
        fetchLastOperation();
    }

    function filterDateHandler(event) {
        setFilterDate(event.target.value);
    }

    function filterAmountHandler(event) {
        setFilterAmount(event.target.value);
    }

    function filterDescriptionHandler(event) {
        setFilterDescription(event.target.value);
    }

    function filterAccountHandler(event) {
        setFilterAccount(event.target.value);
    }

    useLayoutEffect(() => {
        fetchLastOperation();
    }, []);

    return (
        <div className="last-operations">
            <table>
                <tr>
                    <th>Data</th>
                    <th>Kwota</th>
                    <th>Opis</th>
                    <th>Rachunek</th>
                    <th>Akcje</th>
                </tr>
                <tr>
                    <td>
                        <input type="date"
                               value={'.*' == filterDate ? '' : filterDate}
                               onChange={filterDateHandler}/>
                    </td>
                    <td>
                        <input type="text"
                               value={'.*' == filterAmount ? '' : filterAmount}
                               onChange={filterAmountHandler}/>
                    </td>
                    <td><input className="filter-input-description" type="text"
                               value={'.*' == filterDescription ? '' : filterDescription}
                               onChange={filterDescriptionHandler}/>
                    </td>
                    <td><input className="filter-input-account" type="text"
                               value={'.*' == filterAccount ? '' : filterAccount}
                               onChange={filterAccountHandler}/>
                    </td>
                    <td>
                        <button onClick={onRefreshClicked}>Odśwież</button>
                    </td>
                </tr>
                {
                    lastOperations.filter(function (el) {
                        return (
                            el.operationDate.match(filterDate)
                            && el.amount.toLocaleString().match(filterAmount)
                            && el.description.toLowerCase().match(filterDescription.toLowerCase())
                            && el.bankAccount.toLowerCase().match(filterAccount.toLowerCase())
                        );
                    }).slice(0, 15).map(operation => (
                        <tr>
                            <td>{operation.operationDate}</td>
                            <td>{operation.amount} {operation.currency}</td>
                            <td>{operation.description}</td>
                            <td>{operation.bankAccount}</td>
                            <td></td>
                        </tr>
                    ))
                }
            </table>
        </div>
    );
}

export default LastOperations;