import React from "react";

function ExpenseCategoryDropDown(props) {
    function selectHandler(event) {
        props.onExpenseCategorySelected(event.target.value);
    }

    return (<div>
        <label>Kategoria: </label>
        <select disabled={props.categoryHidden} value={props.value} onChange={selectHandler}>
            <option disabled selected value="BLANK"> --- Wybierz kategorie ---</option>
            {props.expenseCategories.map((category) => (
                <option
                    value={category.expanseCategoryId}>{category.expanseCategoryName} - {category.categoryType}</option>))}
        </select>
    </div>);
}

export default ExpenseCategoryDropDown;