import React from "react";

function OperationTypeRadio(props) {

    function selectHandler(event) {
        props.onTypeSelected(event.target.value);
    }

    return (
        <div>
            <div>
                <input className="radio-input" onChange={selectHandler}  checked={props.value=='EXPENSE'} type="radio" value="EXPENSE" name="operationType"/>Wydatek
                <input className="radio-input" onChange={selectHandler}  checked={props.value=='INCOME'} type="radio" value="INCOME" name="operationType"/>Wpływ
            </div>
        </div>
    );
}

export default OperationTypeRadio;