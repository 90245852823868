import React from "react";

function BankAccountDropDown(props) {
    function selectHandler(event) {
        props.onBankAccountSelected(event.target.value);
    }

    return (<div className="dropdown">
        <label>Rachunek: </label>
        <select value={props.value} onChange={selectHandler}>
            <option disabled selected value="BLANK"> --- Wybierz rachunek bankowy ---</option>
            {props.bankAccounts.map((bankAccount) => (
                <option value={bankAccount.bankAccountId}>{bankAccount.bankName}__{bankAccount.accountName}; saldo: {bankAccount.balance}</option>))}
        </select>
    </div>);
}

export default BankAccountDropDown;