import React from "react";

function VirtualAccountDropDown(props) {
    function selectHandler(event) {
        props.onVirtualAccountSelected(event.target.value);
    }

    return (<div>
        <label>Rachunek wirtualny: </label>
        <select value={props.value} onChange={selectHandler}>
            <option disabled selected value="BLANK"> --- Wybierz rachunek wirtualny ---</option>
            {props.virtualAccounts.map((virtualAccount) => (
                <option value={virtualAccount.virtualAccountId}>{virtualAccount.virtualAccountName} saldo:{virtualAccount.balance}</option>))}
        </select>
    </div>);
}

export default VirtualAccountDropDown;