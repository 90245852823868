import React, {useState} from "react";
import './Card.css';
import StartPage from "../StartPage/StartPage";
import LoginPage from "../LoginPage/LoginPage";

function Card() {

    const server = 'http://77.55.216.116:8888';
    // const server = 'http://localhost:8080';

    const [userState, setUserState] = useState('LOGGED_OUT');

    function onCredentialSubmitted(status) {
        if ('SUCCESS' == status) {
            setUserState('LOGGED_IN');
            fetchCurrentMonthData();
        } else {
            setUserState('LOGGED_OUT');
        }
    }

    function logOutClicked() {
        setUserState('LOGGED_OUT');
    }

    function fetchCurrentMonthData() {
        fetch(server + '/expense/getCurrentMonthPerDay')
            .then(response => {
                return response.json();
            })
        // .then((data) => {
        //     setBankAccounts(data.bankAccounts);
        //     setExpenseCategories(data.expenseCategories)
        //     setLocks(data.lockTypes);
        // }).catch((any) => {
        // setOperationInitSuccess(false)
        // });
    }

    if (userState == 'LOGGED_IN') {
        return (
            <div className="container">
                <div className="main-card">
                    <StartPage
                        server={server}
                        logOutClicked={logOutClicked}
                    />
                </div>
            </div>
        );

    } else {
        return (
            <div className="container">
                <div className="main-card">
                    <LoginPage onCredentialSubmitted={onCredentialSubmitted}/>
                </div>
            </div>
        );
    }
}

export default Card;
