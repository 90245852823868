import React, {useState} from "react";
import VirtualAccountDropDown from "./drop-down/VirtualAccountDropDown";
import LockDropDown from "./drop-down/LockDropDown";
import ExpenseCategoryDropDown from "./drop-down/ExpenseCategoryDropDown";

function NewVirtualOperationForm(props) {

    // component state
    const [locksHidden, setLocksHidden] = useState(true);
    const [expenseCategories, setExpenseCategories] = useState([]);
    const [virtualAccountDropDownValue, setVirtualAccountDropDownValue] = useState('BLANK');
    const [expenseCategoryDropDownValue, setExpenseCategoryDropDownValue] = useState('BLANK');
    const [lockDropDownValue, setLockDropDownValue] = useState('BLANK');

    // submit state
    const [amount, setAmount] = useState('');
    const [virtualAccountId, setVirtualAccountId] = useState('');
    const [expenseCategoryId, setExpenseCategoryId] = useState('');
    const [lockType, setLockType] = useState(null);

    function onSelectVirtualAccount(virtualAccountId) {
        const virtualAccountForSelectedId = props.virtualAccounts.filter(function (el) {
            return el.virtualAccountId == virtualAccountId;
        })[0];
        const categoriesForSelectedAccount = props.expenseCategories.filter(function (el) {
            return el.categoryType == virtualAccountForSelectedId.categoryType;
        });
        setExpenseCategories(categoriesForSelectedAccount)
        setLocksHidden(virtualAccountForSelectedId.categoryType != 'COMMON')
        setVirtualAccountId(virtualAccountId);
        setVirtualAccountDropDownValue(virtualAccountId);
    }

    function amountChangeHandler(event) {
        setAmount(event.target.value);
    }

    function onExpenseCategorySelected(expenseCategoryId) {
        setExpenseCategoryId(expenseCategoryId);
        setExpenseCategoryDropDownValue(expenseCategoryId);
    }

    function onLockSelected(lockType) {
        setLockType('BLANK' == lockType ? null : lockType);
        setLockDropDownValue(lockType);
    }

    function onFormSubmit() {
        const virtualOperationBody = props.operationType == "EXPENSE"
            ? {
                "amount": amount,
                "virtualAccountId": virtualAccountId,
                "expenseCategoryId": expenseCategoryId,
                "lockType": lockType
            }
            : {
                "amount": amount,
                "virtualAccountId": virtualAccountId,
            };
        props.onVirtualOperationSubmit(virtualOperationBody);
        setAmount('');
        setVirtualAccountId('');
        setExpenseCategoryId('');
        setLockType(null);
        setLocksHidden(true);
        setExpenseCategories([]);
        setVirtualAccountDropDownValue('BLANK');
        setExpenseCategoryDropDownValue('BLANK');
        setLockDropDownValue('BLANK');
    }

    return (
        <div className="new-operation-form-item virtual-operation-form">
            <div className="new-operation-form-item virtual-amount">
                <label>Kwota: </label>
                <input type="number" value={amount}
                       onChange={amountChangeHandler}/>
            </div>
            <div className="new-operation-form-item">
                <VirtualAccountDropDown value={virtualAccountDropDownValue}
                                        onVirtualAccountSelected={onSelectVirtualAccount}
                                        virtualAccounts={props.virtualAccounts}/>
            </div>
            <div className="new-operation-form-item">
                <ExpenseCategoryDropDown categoryHidden={props.operationType == "INCOME"}
                                         value={expenseCategoryDropDownValue}
                                         expenseCategories={expenseCategories}
                                         onExpenseCategorySelected={onExpenseCategorySelected}/>
            </div>
            <div className="new-operation-form-item">
                <LockDropDown value={lockDropDownValue} locksHidden={locksHidden} locks={props.locks}
                              onLockSelected={onLockSelected}/>
            </div>
            <div className="new-operation-form-item">
                <button onClick={onFormSubmit}>Zatwierdź operację wirtulną</button>
            </div>
        </div>
    );
}

export default NewVirtualOperationForm;