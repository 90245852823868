import './Operations.css'
import React, {useState} from "react";
import NewOperationForm from "../new-operation/NewOperationForm";
import LastOperations from "../LastOperations/LastOperations";

function Operations(props) {

    const [operationFormVisible, setOperationFormVisible] = useState(false);
    const [initData, setInitData] = useState({});
    const [loadingInfoHidden, setLoadingInfoHidden] = useState(true);
    const [loadingFailedHidden, setFailedInfoHidden] = useState(true);

    function onNewOperationClicked() {
        setLoadingInfoHidden(false);
        fetchInit();
    }

    function onFormSubmit() {
        props.onFormSubmit(onFormSubmit);
        setOperationFormVisible(false);
    }

    function onCancelOperationFormClicked() {
        setOperationFormVisible(false);
    }

    function fetchInit() {
        fetch(props.server + '/expense/init')
            .then(response => {
                return response.json();
            })
            .then((data) => {
                console.log(data);
                setInitData(data);
                setLoadingInfoHidden(true);
                setOperationFormVisible(true);
            });
    }

    if (operationFormVisible) {
        return (
            <div className="operations">
                <button className="new-operation-button" onClick={onNewOperationClicked}>Nowa operacja</button>
                <NewOperationForm
                    server={props.server}
                    initData={initData}
                    onCancelOperationFormClicked={onCancelOperationFormClicked}
                    onFormSubmit={onFormSubmit}
                />
                <LastOperations
                    server={props.server}
                />
            </div>
        );
    } else {
        return (
            <div className="operations">
                <button className="new-operation-button" onClick={onNewOperationClicked}>Nowa operacja</button>
                <p className="loading-in-progress" hidden={loadingInfoHidden}> Trwa wczytywanie danych !!!</p>
                <p className="loading-failed" hidden={loadingFailedHidden}> Wczytywanie danych nie powiodło się !!!</p>
                <LastOperations
                    server={props.server}/>
            </div>
        );
    }
}

export default Operations;