import React, {useState} from "react";
import BankAccountDropDown from "./drop-down/BankAccountDropDown";
import NewVirtualOperationForm from "./NewVirtualOperationForm";
import OperationTypeRadio from "./radio/OperationTypeRadio";
import './NewOperationForm.css';
import VirtualOperations from "../VirtualOperations/VirtualOperations";

function NewOperationForm(props) {
    // component state
    const [virtualOperations, setVirtualOperations] = useState([]);
    const [amountLeft, setAmountLeft] = useState('');
    const [bankAccountDropDownValue, setBankAccountDropDownValue] = useState('BLANK');
    const [operationType, setOperationType] = useState('EXPENSE');


    // init state
    const [bankAccounts, setBankAccounts] = useState(props.initData.bankAccounts);
    const [virtualAccounts, setVirtualAccounts] = useState([]);
    const [expenseCategories, setExpenseCategories] = useState(props.initData.expenseCategories);
    const [locks, setLocks] = useState(props.initData.lockTypes);
    // submit state
    const [operationDate, setOperationDate] = useState('');
    const [amount, setAmount] = useState('');
    const [description, setDescription] = useState('');
    const [accountId, setAccountId] = useState('');

    function onCancelOperationFormClicked() {
        setOperationDate('');
        setAmount('');
        setDescription('');
        setAccountId('');
        setBankAccounts(bankAccounts);
        setVirtualOperations([]);
        setBankAccountDropDownValue('BLANK');
        setAmountLeft(0);
        setOperationType('EXPENSE');
        props.onCancelOperationFormClicked(onCancelOperationFormClicked);
    }

    function operationDateChangeHandler(event) {
        setOperationDate(event.target.value);
    }

    function amountChangeHandler(event) {
        const oldAmount = amount;
        const oldAmountLeft = amountLeft;
        const amountConsumed = oldAmount - oldAmountLeft;
        const newAmount = event.target.value;
        setAmount(newAmount);
        setAmountLeft(newAmount - amountConsumed);
    }

    function descriptionChangeHandler(event) {
        setDescription(event.target.value);
    }

    function onBankAccountSelected(bankAccountId) {
        const bankAccountForSelectedId = bankAccounts.filter(function (el) {
            return el.bankAccountId == bankAccountId;
        });
        setVirtualAccounts(bankAccountForSelectedId[0].virtualAccounts);
        setAccountId(bankAccountId);
        setBankAccountDropDownValue(bankAccountId);
    }

    function onVirtualOperationSubmit(virtaulOperation) {
        const refreshedVirtaulOperations = [
            ...virtualOperations, virtaulOperation
        ];
        setVirtualOperations(refreshedVirtaulOperations);
        setAmountLeft(amountLeft - virtaulOperation.amount)
    }

    function onTypeSelected(operationType) {
        setOperationType(operationType);
        console.log(operationType);
    }

    function onFormSubmit() {
        const isExpense = operationType == "EXPENSE";
        const operationSubmitBody = isExpense
            ? {
                "operationDate": operationDate,
                "amount": amount,
                "description": description,
                "accountId": accountId,
                "categorizedExpenses": virtualOperations
            }
            : {
                "operationDate": operationDate,
                "amount": amount,
                "description": description,
                "accountId": accountId,
                "categorizedIncomes": virtualOperations
            };
        console.log(operationSubmitBody);
        const path = isExpense ? '/expense/new' : '/income/new';
        fetch(props.server + path, {
            method: 'POST',
            body: JSON.stringify(operationSubmitBody),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setOperationDate('');
        setAmount('');
        setDescription('');
        setAccountId('');
        setBankAccounts(bankAccounts);
        setVirtualOperations([]);
        setBankAccountDropDownValue('BLANK');
        setAmountLeft(0);
        setOperationType('EXPENSE')
        props.onFormSubmit(onFormSubmit);
    }

    return (
        <div className="new-operation-form">
            <div className="new-operation-form-item label-operation">
                <p>OPERCJA</p>
            </div>
            <div className="new-operation-form-item label-virtual-operation">
                <p>OPERCJA WIRTUALNA</p>
            </div>
            <div className="new-operation-form-item label-virtual-operations-list">
                <p>POZOSTAŁA KWOTA: {amountLeft}</p>
            </div>
            <div className="new-operation-form-item type-radio">
                <OperationTypeRadio value={operationType} onTypeSelected={onTypeSelected}/>
            </div>
            <div className="new-operation-form-item date">
                <label>Data operacji: </label>
                <input type="date" value={operationDate}
                       onChange={operationDateChangeHandler}/>
            </div>
            <div className="new-operation-form-item amount">
                <label>Kwota: </label>
                <input type="number" value={amount}
                       onChange={amountChangeHandler}/>
            </div>
            <div className="new-operation-form-item description">
                <label>Opis: </label>
                <input type="text" value={description}
                       onChange={descriptionChangeHandler}/>
            </div>
            <div className="new-operation-form-item account-dropdown">
                <BankAccountDropDown value={bankAccountDropDownValue} onBankAccountSelected={onBankAccountSelected}
                                     bankAccounts={bankAccounts}/>
            </div>
            <NewVirtualOperationForm virtualAccounts={virtualAccounts}
                                     onVirtualOperationSubmit={onVirtualOperationSubmit}
                                     locks={locks}
                                     expenseCategories={expenseCategories}
                                     operationType={operationType}/>
            <VirtualOperations virtualOperations={virtualOperations} />
            {/*<button className="new-operation-form-item submit-button" disabled={0 == amount || 0 != amountLeft}*/}
            <button className="new-operation-form-item submit-button"
                    onClick={onFormSubmit}>Zatwierdź i wyslij
            </button>
            <button className="cancel-button" onClick={onCancelOperationFormClicked}>X</button>
        </div>
    );
}

export default NewOperationForm;