import React, {useState} from "react";
import '../card/Card.css';
import MenuBar from "../MenuBar/MenuBar";
import PageContent from "../PageContent/PageContent";
import './StartPage.css';

function StartPage(props) {

    const [itemSelected, setItemSelected] = useState('');

    function onCurrentCommonStatusClicked() {
        setItemSelected('CURRENT_COMMON_STATUS');
    }

    function onOperationsClicked() {
        setItemSelected('OPERATIONS');
    }

    function logOutClicked() {
        setItemSelected('');
        props.logOutClicked(logOutClicked);
    }

    return (
        <div className="start-page">
            <MenuBar
                onCurrentCommonStatusClicked={onCurrentCommonStatusClicked}
                onOperationsClicked={onOperationsClicked}
                logOutClicked={logOutClicked}
            />
            <PageContent
                server={props.server}
                itemSelected={itemSelected}
            />
        </div>
    );
}

export default StartPage;
