import React from "react";
import './MenuBar.css';

function MenuBar(props) {

    function onOperationsClicked() {
        props.onOperationsClicked(onOperationsClicked);
    }

    function onCurrentCommonStatusClicked() {
        props.onCurrentCommonStatusClicked(onCurrentCommonStatusClicked);
    }

    function logOutClicked() {
        props.logOutClicked(logOutClicked);
    }

    return (
        <div className="menu">
            <button className="menu-button" onClick={onCurrentCommonStatusClicked}>Raport bieżący</button>
            <button className="menu-button" onClick={onOperationsClicked}>Operacje</button>
            <button className="menu-button">bla bla</button>
            <button className="menu-button">bla bla</button>
            <button className="menu-button logout-button" onClick={logOutClicked}>Wyloguj</button>
        </div>
    );
}

export default MenuBar;
