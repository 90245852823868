import './VirtualOperations.css';

function VirtualOperations (props) {
    return (
        <div className="new-operation-form-item virtual-operations-list">
            <ul>
                <li>Kwota </li>
                {props.virtualOperations.map(virtualOperation => (
                        <li>{virtualOperation.amount}</li>
                    ))
                }
            </ul>
        </div>
    );
}

export default VirtualOperations;