import React from "react";
import './PageContent.css';
import Operations from "../Operations/Operations";
import CurrentCommonStatus from "../CurrentCommonStatus/CurrentCommonStatus";

function StartPage(props) {

    if ('CURRENT_COMMON_STATUS' == props.itemSelected) {
        return (
            <div className="page-content">
                <CurrentCommonStatus
                    server={props.server}
                />
            </div>
        );
    } else if ('OPERATIONS' == props.itemSelected) {
        return (
            <div className="page-content">
                <Operations
                    server={props.server}
                />
            </div>
        );
    }
}

export default StartPage;
