import React, {useState} from "react";
import './LoginPage.css';

function LoginPage(props) {

    const [enteredUserName, setEnteredUserName] = useState('');
    const [enteredPassword, setEnteredPassword] = useState('');

    function passwordEnterHandler(event) {
        setEnteredPassword(event.target.value);
    }

    function userNameEnterHandler(event) {
        setEnteredUserName(event.target.value);
    }

    function sha512(str) {
        return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str))
            .then(buf => {
                return Array.prototype.map.call(new Uint8Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
            })
    }

    const hashCode = s =>
        s.split('').reduce((a, b) => {
            a = (a << 5) - a + b.charCodeAt(0);
            return a & a;
        }, 0);

    function passwordSubmitted() {
        setEnteredUserName('');
        setEnteredPassword('');
        props.onCredentialSubmitted(100881330 == hashCode(enteredUserName) && 1601655489 == hashCode(enteredPassword) ? 'SUCCESS' : 'FAILED');
    }

    return (
        <div className='login-page'>
            <div className="user-label">
                <label>
                    Użytkownik:
                </label>
            </div>
            <div className='user-input'>
                <input className="text-input" type="text" value={enteredUserName}
                       onChange={userNameEnterHandler}/>
            </div>
            <div className='password-label'>
                <label>Hasło: </label>
            </div>
            <div className='password-input'>
                <input className="text-input" type="password" value={enteredPassword}
                       onChange={passwordEnterHandler}/>
            </div>
            <div className='login-submit'>
                <button className="button button-submit" onClick={passwordSubmitted}>Zatwierdź</button>
            </div>
        </div>
    );
}

export default LoginPage;