import {useLayoutEffect, useState} from "react";

function CurrentCommonStatus(props) {

    const [currentCommonStatusData, setCurrentCommonStatusData] = useState({});

    function fetchCurrentCommonStatusData() {
        fetch(props.server + '/expense/getCurrentMonthPerDay')
            .then(response => {
                return response.json();
            })
            .then((data) => {
                console.log(data);
                setCurrentCommonStatusData(data);
            });
    }

    useLayoutEffect(() => {
        fetchCurrentCommonStatusData();
    }, []);

    return (
        <div>
            <p>{currentCommonStatusData.differenceSum}</p>
        </div>
    )
}

export default CurrentCommonStatus;